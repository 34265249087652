import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import Layout from "../Container/homeContainer";
import User from "../Container/userContainer";
import Categories from "../Container/categoryContainer";
import Product from "../Container/productContainer";
import Sale from "../Container/saleContainer";
import Dashboard from "../Container/dashboardContainer";
import Sale_history from "../Container/salehistoryContainer";
import Sale_Edit from "../Container/saleeditContainer";
import Sale_Report from "../Container/salerportContainer";
import Login from "../Container/loginContainer";
import Sale_invoice from "../Container/invoiceContainer";
import Productview from "../Components/products/productview";
import Singleproductdetail from "../Components/products/singleproductdetail";

function Routing(props) {
  const user = props.state.setuser.user;
  return (
    <BrowserRouter>
      <ProSidebarProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={!user ? <Login /> : <Navigate to={"/dashboard"} />}
            ></Route>
            <Route
              path="/dashboard"
              element={user ? <Dashboard /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/user"
              element={user ? <User /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/categories"
              element={user ? <Categories /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/products"
              element={user ? <Product /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/Sale"
              element={user ? <Sale /> : <Navigate to={"/"} />}
            ></Route>

            <Route path="/sale_history" element={<Sale_history />}></Route>
            <Route
              path="/sale_edit"
              element={user ? <Sale_Edit /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/sale_report"
              element={user ? <Sale_Report /> : <Navigate to={"/"} />}
            ></Route>
          </Route>
          <Route
            path="/invoice/:name"
            element={user && <Sale_invoice />}
          ></Route>
          <Route
            path="/singleproductdetail/:id"
            element={<Singleproductdetail />}
          ></Route>
          <Route path="/products_detail/:id" element={<Productview />}></Route>
        </Routes>
      </ProSidebarProvider>
    </BrowserRouter>
  );
}

export default Routing;
