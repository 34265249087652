import React, { useState, useEffect } from "react";
import Productwidget from "./productwidget";
import went_wrong_toast from "../alerts/went_wrong_toast";
import "./product.css";
import { useParams } from "react-router-dom";

function Productview(props) {
  const { id } = useParams();
  const [data, setdata] = useState(null);
  const [current_user, setcurrent_user] = useState(null);

  useEffect(() => {
    const fetchWorkouts = async () => {
      var url = `https://rentalapi.saer.pk/api/products/?user_id=${id}`;
      const response = await fetch(`${url}`, {});
      const json = await response.json();

      if (response.ok) {
        setdata(json);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };
    const fetchuser = async () => {
      var url = `https://rentalapi.saer.pk/api/users/${id}/`;
      const response = await fetch(`${url}`, {});
      const json = await response.json();

      if (response.ok) {
        setcurrent_user(json);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };

    fetchWorkouts();
    fetchuser();
  }, []);

  return (
    <div className="p-3">
      <div className="position card">
        {current_user && <h1>{current_user.profile.title}</h1>}
      </div>

      {data && (
        <div className="row p-3">
          {data.map((item) => {
            return (
              <Productwidget
                key={item.id}
                img={item.images}
                name={item.name}
                category={item.category_name}
                status={item.status}
                description={item.description}
                id={item.id}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Productview;
