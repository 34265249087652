import React, { useState, useEffect } from "react";
import went_wrong_toast from "../alerts/went_wrong_toast";
import "./product.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IconButton } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

function Singleproductdetail() {
  const { id } = useParams();
  const [data, setdata] = useState(null);
  const [image, setimage] = useState("");
  const [all_image, setall_image] = useState("");
  const [current_user, setcurrent_user] = useState(null);

  useEffect(() => {
    const fetchWorkouts = async () => {
      var url = `https://rentalapi.saer.pk/api/products/${id}/`;
      const response = await fetch(`${url}`, {});
      const json = await response.json();

      if (response.ok) {
        setdata(json);
        if (json.images.length > 0) {
          setall_image(json.images);
          setimage(json.images[0]);
        }
        fetchuser(json);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };

    fetchWorkouts();
  }, []);

  const fetchuser = async (input) => {
    var url = `https://rentalapi.saer.pk/api/users/${input.user}/`;
    const response = await fetch(`${url}`, {});
    const json = await response.json();

    if (response.ok) {
      setcurrent_user(json);
    }
    if (!response.ok) {
      went_wrong_toast();
    }
  };

  const moveback = () => {
    if (image) {
      const index = all_image.indexOf(image);
      if (index !== 0) {
        setimage(all_image[index - 1]);
      }
    }
  };

  const movenext = () => {
    if (image) {
      const index = all_image.indexOf(image);
      if (index !== all_image.length - 1) {
        setimage(all_image[index + 1]);
      }
    }
  };

  const changeimage = (e, url) => {
    e.preventDefault();
    setimage(url);
  };

  return (
    <div className="p-3">
      <div className=" position card mb-3">
        {current_user && <h1>{current_user.profile.title}</h1>}
      </div>

      {data && (
        <>
          <div className="text-end">
            {current_user && (
              <Link to={`/products_detail/${current_user.id}`} target="blank">
                <Button
                  type="button"
                  className="mb-2 me-2"
                  variant="outline-primary"
                >
                  <VisibilityIcon /> Show Products
                </Button>
              </Link>
            )}
          </div>
          <div className="row">
            <div className="col-sm-5 text-center">
              <IconButton onClick={moveback}>
                <ArrowBackIosIcon />
              </IconButton>
              <img
                className="image"
                src={image.picture}
                alt="image"
                width={400}
                height={500}
              ></img>
              <IconButton onClick={movenext}>
                <ArrowForwardIosIcon />
              </IconButton>
              <div
                className=" mt-3 d-flex justify-content-center"
                style={{ overflowX: "auto" }}
              >
                {all_image.map((item) => {
                  return (
                    <span
                      key={item.id}
                      onClick={(e) => {
                        changeimage(e, item);
                      }}
                    >
                      <img
                        className="me-3 img-list"
                        src={item.picture}
                        alt="image"
                        width={60}
                        height={60}
                      ></img>
                    </span>
                  );
                })}
              </div>
            </div>

            <div className="col-sm-6">
              <p className="text-dark mt-3 mb-0 ">
                <strong>{data.name.toUpperCase()}</strong>
              </p>
              <p className="text-dark mt-0 ">{data.category_name}</p>
              {/* <p className={data.status.toLowerCase()==='available' ? 'text-success m-0 mt-3':'text-danger m-0 mt-3'}>{data.status.toUpperCase()}</p> */}
              <p className="text-dark mt-3">{data.description}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Singleproductdetail;
